import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const BuyAnEssayOnline = () => (
  <Layout className="IndexPage BuyAnEssayOnline">
    <Helmet>
      <title>Buy Essay Tailored to Your Personal Requirements | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Want to buy an essay? Our professionals are available to you 24/7. Place your order right now and get a professional to help you handle all your writing troubles."
      />
      <link rel="alternate" href="https://www.customwritings.com/buy-essay.html" hrefLang="x-default" />
      <link rel="alternate" href="https://www.customwritings.com/buy-essay.html" hrefLang="en-us" />
      <link rel="alternate" href="https://ca.customwritings.com/buy-an-essay-online.html" hrefLang="en-ca" />
      <link rel="canonical" href="https://ca.customwritings.com/buy-an-essay-online.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Buy an essay and have your homework fully covered</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Ask for help from our experts and get plagiarism-free essays delivered on time</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">The best place to buy essay online for Canadian students</h2>
      <p>
        The student workload is growing larger with every passing year. Although today one has plenty of technological
        advantages to help with many of the tasks they may encounter throughout high school and college, the sheer
        amount of homework and other responsibilities placed upon the average student has made it hard to keep up.
        It’s no wonder then, that so many students find themselves incapable of coping with this burden and
        occasionally buy essay writing to get through particularly rough patches of their academic life. It may be a
        convenient and viable solution, at least in some cases, but if you study in Canada, you have to be extra
        careful when buy an essay from an online academic assistance service, especially if you’re dealing with it for
        the first time. In addition to all the usual concerns (the dangers of plagiarism, the possibility of late
        completion or poor quality, etc.), you have to make sure you buy essay paper from an agency that regularly
        deals with Canadian students. The thing is, although the educational systems in Canada and most other
        English-speaking countries are more or less similar, there are differences. If your writer is unfamiliar with
        the terminology, formatting standards, and peculiarities of Canadian English, you risk getting a paper that
        will, at the very least, look odd and out of place. At most, it will fail to follow the conventions accepted
        in Canadian universities, thus getting you into trouble. In other words, when you buy essay online, make sure
        to ask if the service in question is used to helping students from Canada.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">Buy essay paper writing tailored for Canadian students</h2>
      <p>
        With CustomWritings.com, you don’t have to worry about such issues—simply specify that you want to buy an
        essay written according to Canadian standards, and we’ll take care of the rest. If you want, we can even find
        you a native English speaker who uses Canadian English in everyday life to make sure it looks completely
        authentic. This, however, isn’t the only advantage you get by using our service. Here are a few other things
        that may interest you:
      </p>
      <ul>
        <li>
          Affordability. Our prices start as low as $10 per page and mostly depend on the degree of urgency rather
          than the academic level. In other words, you can even buy cheap essay at PhD level if you place an order
          while there’s still time to spare.
        </li>
        <li>
          Complete originality. CustomWritings.com is a place where you can buy custom essay papers and be completely
          sure that they’re written from scratch. We check every text we sell using advanced anti-plagiarism tools and
          never send an assignment to the client if it has even a hint of copy/pasting.
        </li>
        <li>
          24/7 availability. Our service is where you can buy essay at this very moment, even if it’s the middle of
          the night on a weekend. Our customer support team is always online to accept your orders, and our writers
          come from a variety of time zones so that some of them are always on standby and ready to start working at
          any moment.
        </li>
        <li>
          Security. We use the latest cybersecurity solutions to ensure that when you buy an essay online, it remains
          a private affair between you and our service.
        </li>
        <li>
          Guarantees. When you buy online essay, we promise to provide any number of free revisions if you ask for
          them within seven days of receiving your assignments (as long as you don’t contradict your original
          instructions). In a variety of situations, we’re ready to give you a full or partial refund (you can find
          the complete list of contingencies falling under this guarantee on our money-back guarantee page).
        </li>
      </ul>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-3">Buy custom essay and make your life in college easier</h2>
      <p>
        Anything that takes a bit of the burden off of a student’s shoulders is a significant advantage when your
        college is making your life difficult. So don’t put it off any longer—buy custom essay from our service and
        let us help you. If you place an order as soon as you get an assignment, you can buy essay cheap—the closer to
        the deadline, the more expensive it will be. We’re ready to help you with assignments of any type and degree
        of complexity. If you think that your topic is too unusual, try us—we have been working in this industry for
        years and have had plenty of time to gather the best specialists available online. Today we have experts in
        virtually every discipline and topic taught in high school and college, and we’re are eager to help you out.
        So buy essay online cheap, give us the instructions, and simply wait—we’ll contact you if we need any
        additional information to write the best paper possible!
      </p>
    </Section>
  </Layout>
);

export default BuyAnEssayOnline;
